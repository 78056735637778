<template>
  <Modal
    v-if="showModal"
    :show-modal="showModal"
    @clicked="$emit('modal-clicked', false, context)"
  >
    <template v-slot:header>
	    <div v-if="userData.isBanned">
		    {{ trans('MODAL_TITLE:AccountBlocked') }}
	    </div>
      <div v-else-if="isFake">
        {{ trans('MODAL_TITLE:Attention') }}
      </div>
      <div v-else-if="!userData.isSubscriptionActive">
        {{ trans('MODAL_TITLE:NoSubscription') }}
      </div>
      <div v-else-if="!userData.isVerified && !isFake">
        {{ trans('MODAL_TITLE:NoVerification') }}
      </div>
      <div v-else>
        {{ trans('MODAL_TITLE:Attention') }}
      </div>
    </template>
    <template v-slot:content>
      <div class="lg:pl-3">
        <div v-if="userData.isBanned">
          {{ trans('MODAL_DESCRIPTION:BlockedAccount') }}
        </div>
        <div v-else-if="isFake" class="flex flex-col gap-3">
          <div v-if="context==='remember'">
            {{ trans('MODAL_DESCRIPTION:CantRememberFakeProfile') }}
          </div>
	        <div v-if="context==='skip'">
		        {{ trans('MODAL_DESCRIPTION:CantSkipFakeProfile') }}
	        </div>
          <div v-if="context==='chat'">
            {{ trans('MODAL_DESCRIPTION:CantChatWithFakeProfile') }}
          </div>
          <div>
            {{ trans('MODAL_DESCRIPTION:SearchFakeModalStartSearchToSeeRealProfiles') }}
          </div>
        </div>
        <div v-else-if="!userData.isSubscriptionActive && !isFake">
          {{ trans('MODAL_DESCRIPTION:SendingMessagesOnlyWithSubscription') }}
        </div>
        <div v-else-if="!userData.isVerified && !isFake">
          {{ trans('MODAL_DESCRIPTION:NewContactOnlyWithVerification') }}
        </div>
	      <div v-else-if="currentThread.targetUser.isBanned || currentThread.bannedAt">
		      {{ trans('MODAL_DESCRIPTION:CantSendMessageToBlockedUser') }}
	      </div>
	      <div v-else-if="isEmptyChatMessage(currentMessage.message)">
		      {{ trans('MODAL_DESCRIPTION:EmptyMessage') }}
	      </div>
	      <div v-else-if="currentThread.targetUser.isDeleted">
		      {{ trans('MODAL_DESCRIPTION:CantSendMessageToDeletedUser') }}
	      </div>
        <div v-else>
          {{ trans('MODAL_DESCRIPTION:NewContactWhenSubscribedAndVerified') }}
        </div>
      </div>
    </template>
    <template v-slot:footer>
	    <template v-if="userData.isBanned">
		    <Button
          :text="trans('BUTTON:Ok')"
          @clicked="$emit('modal-clicked', false)"
		    >
		    </Button>
	    </template>
	    <template v-else>
		    <Button
          variant="buttonRedOutlined"
          :text="isFake ? trans('BUTTON:Close') : trans('BUTTON:Cancel')"
          @clicked="$emit('modal-clicked', false)"
		    >
		    </Button>
	    </template>
      <template v-if="!userData.isSubscriptionActive && !isFake && !userData.isBanned">
        <Button
            :text="trans('BUTTON:BuySubscription')"
            :to="{name: 'subscription'}"
        >
        </Button>
      </template>
      <template v-else-if="!userData.isVerified && !isFake && !userData.isBanned">
        <Button
            :text="trans('BUTTON:VerificationShow')"
            :to="{name: 'verification'}"
        >
        </Button>
      </template>
      <template v-else-if="isFake">

      </template>
    </template>
  </Modal>
</template>

<script>
import Button from "@/utils/crud/components/Button.vue";
import Modal from "@/utils/modal/Modal.vue";
import {mapGetters} from "vuex";
import {isEmptyChatMessage} from "@/common/helpers/utils";

export default {
  name: "FailedActionModal",
  components: {Modal, Button},
	data() {
		return {
			isEmptyChatMessage: isEmptyChatMessage,
		}
	},
  props: {
    showModal: false,
    isFake: {
      type: Boolean,
    },
    context:{
      type: String,
    },
  },
  computed: {
    ...mapGetters('account', ['userData']),
    ...mapGetters('chat', ['currentThread', 'currentMessage']),
  },
}
</script>